<template>
  <el-table :data="moudForm.moud_mtrl_list" border @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="48" align="center">
      <template v-slot="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>

    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">供应商</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.supp_name'">
          <el-select v-model="moudForm.moud_mtrl_list[scope.$index].supp_id" placeholder="请选择物料类型" clearable disabled>
            <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">材料编号</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.mdrl_no'" :rules="[{ required: true }]">
          <el-input
            @change="mtrlNoChange(scope)"
            :disabled="true"
            v-model="moudForm.moud_mtrl_list[scope.$index].mdrl_no"
            maxlength="10"
            show-word-limit
            placeholder="暂无物料编号"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">材料名称</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.mdrl_name'" :rules="[{ required: true }]">
          <el-tooltip
            class="item"
            effect="light"
            :content="moudForm.moud_mtrl_list[scope.$index].mtrl_name ? moudForm.moud_mtrl_list[scope.$index].mdrl_name + '' : ''"
            placement="top-start"
          >
            <el-input
              :disabled="true"
              v-model="moudForm.moud_mtrl_list[scope.$index].mdrl_name"
              maxlength="30"
              show-word-limit
              placeholder="暂无材料名称"
            >
            </el-input>
          </el-tooltip>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">材料类型</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.mdrl_type'" :rules="[{ required: true }]">
          <el-select
            :disabled="moudForm.moud_mtrl_list[scope.$index].disabledFlag"
            v-model="moudForm.moud_mtrl_list[scope.$index].mdrl_type"
            placeholder="请选择材料类型"
          >
            <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">材料规格</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.mdrl_spec'">
          <el-input disabled show-word-limit v-model="moudForm.moud_mtrl_list[scope.$index].mdrl_spec" placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column label="重量" show-word-limit>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.mdrl_weight'">
          <el-tooltip
            class="item"
            effect="light"
            :content="moudForm.moud_mtrl_list[scope.$index].mtrl_spec ? moudForm.moud_mtrl_list[scope.$index].mtrl_spec + '' : ''"
            placement="top-start"
          >
            <el-input
              :disabled="moudForm.moud_mtrl_list[scope.$index].disabledFlag"
              maxlength="50"
              v-model="moudForm.moud_mtrl_list[scope.$index].mdrl_weight"
              placeholder="暂无规格"
            ></el-input>
          </el-tooltip>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column label="物料生产价">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.mdrl_price'">
          <el-input
            disabled
            show-word-limit
            maxlength="12"
            @change="priceChange(scope, 1)"
            @blur="
              moudForm.moud_mtrl_list[scope.$index].mdrl_price = helper.calcPrice(
                moudForm.moud_mtrl_list[scope.$index].mdrl_price,
                4,
                10000000
              )
            "
            @input="moudForm.moud_mtrl_list[scope.$index].mdrl_price = helper.keepTNum1(moudForm.moud_mtrl_list[scope.$index].mdrl_price)"
            v-model="moudForm.moud_mtrl_list[scope.$index].mdrl_price"
            placeholder="暂无物料生产价"
          >
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column label="物料零售价">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'moud_mtrl_list.' + scope.$index + '.no_mdrl_price'">
          <el-input
            @change="priceChange(scope)"
            @blur="
              moudForm.moud_mtrl_list[scope.$index].no_mdrl_price = helper.calcPrice(
                moudForm.moud_mtrl_list[scope.$index].no_mdrl_price,
                4,
                10000000
              )
            "
            show-word-limit
            maxlength="12"
            v-model="moudForm.moud_mtrl_list[scope.$index].no_mdrl_price"
            @input="
              moudForm.moud_mtrl_list[scope.$index].no_mdrl_price = helper.keepTNum1(moudForm.moud_mtrl_list[scope.$index].no_mdrl_price)
            "
            placeholder="暂无物料零售价"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>

    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料数量</span>
      </template>
      <template v-slot="scope">
        <el-form-item
          label-width="0"
          :prop="'moud_mtrl_list.' + scope.$index + '.mtrl_num'"
          :rules="[{ required: true, trigger: 'change' }]"
        >
          <el-input
            v-if="flag"
            show-word-limit
            maxlength="9"
            @change="priceChange(scope, 1)"
            v-model="moudForm.moud_mtrl_list[scope.$index].mtrl_num"
            @input="moudForm.moud_mtrl_list[scope.$index].mtrl_num = keepTNum1(moudForm.moud_mtrl_list[scope.$index].mtrl_num)"
            placeholder="暂无物料数量"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get, post } from '@api/request';
import { moudAPI } from '@api/modules/moud';
import { suppAPI } from '@api/modules/supp';

export default {
  name: 'SmplEditBomChild',
  props: {
    moudForm: {
      type: Object,
      required: true
    },
    mtrlTypeGroupOpt: {
      type: Array,
      required: true
    },
    stffType: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectionsList: [],
      mtrlType: [
        { id: 1, label: '钢材' },
        { id: 2, label: '辅材' }
      ],

      flag: true,
      suppType: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    getMdrlType(val) {
      return this.mtrlType.find(x => x.id === val)?.label ?? '暂无';
    },

    initData() {
      this.getSuppsList();
    },
    keepTNum1(number) {
      this.$forceUpdate();
      return number.replace(/[^0-9.]/g, '');
    },
    priceChange1(scope) {
      let price = 0.0;
      let mtrlList = this.moudForm.moud_mtrl_list;
      for (let i = 0; i < mtrlList.length; i++) {
        if (mtrlList[i].mtrl_num) {
          price +=
            (parseInt(Number(mtrlList[i].mtrl_retail_price) * 10000) * parseInt(Number(mtrlList[i].mtrl_num) * 10000)) / 10000 / 10000;
        }
      }
      this.$emit('sourPrice', price);
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1, {}).then(res => {
        if (res.data.code === 0) {
          this.suppType = res.data.data;
        }
      });
    },
    // 更改数量修改价格
    priceChange(scope, type) {
      let price = 0.0; //总金额
      let price1 = 0.0; //额外金额
      let mdrlList = this.moudForm.moud_mtrl_list;
      for (let i = 0; i < mdrlList.length; i++) {
        if (mdrlList[i].mtrl_num && mdrlList[i].no_mdrl_price) {
          price += (parseInt(Number(mdrlList[i].no_mdrl_price) * 10000) * parseInt(Number(mdrlList[i].mtrl_num) * 10000)) / 10000 / 10000;
          price1 +=
            (parseInt(Number(mdrlList[i].no_mdrl_price - mdrlList[i].mdrl_price) * 10000) *
              parseInt(Number(mdrlList[i].mtrl_num) * 10000)) /
            10000 /
            10000;
        }
      }
      this.$emit('sourPrice', price, price1);
      // }
      if (type === 1) {
        let val = scope.row.mtrl_num;
        if (val) {
          let temp = val.toString();
          let ind = temp.indexOf('.');
          if (Number(temp) < 10000) {
            if (ind < 0) {
              scope.row.mtrl_num = val + '.0000';
            } else {
              if (temp.length - 1 - ind > 4) {
                scope.row.mtrl_num = temp.slice(0, ind + 5);
              } else if (temp.length - 1 - ind < 4) {
                let index = 4 - (temp.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  temp = temp + '0';
                }
                scope.row.mtrl_num = temp;
              } else {
                scope.row.mtrl_num = temp;
              }
            }
          } else {
            this.$message({ message: '物料数量不能大于9999.9999', type: 'error' });
            scope.row.mtrl_num = '';
          }
        } else {
          scope.row.mtrl_num = '';
        }
      }
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange', this.selectionsList);
    },
    // 物料编号重复提醒
    mtrlNoChange(val) {
      console.log('val', val);
      if (val.row.mtrl_no) {
        get(moudAPI.judgeMoudMtrlNo, { mtrl_no: val.row.mtrl_no })
          .then(res => {
            if (res.data.code === 0) {
              console.log('成功');
            } else if (res.data.code === 7) {
              val.row.mtrl_no = null;
              this.$message({
                type: 'error',
                message: '物料编号重复，请重新填写'
              });
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 数量保留四位
    haveFour(e) {
      // this.flag = false
      // console.log('e',e);
      // if(e === undefined){
      //   return '0.0000'
      // }
      // this.$nextTick(()=>{
      //   this.flag = true
      // })
      // if(val){
      //   let temp = val.toString();
      //   let ind = temp.indexOf('.');
      //   if(Number(temp)<10000){
      //     if(ind<0){
      //         return temp+'.0000'
      //     }else{
      //       if(temp.length-1-ind>4){
      //         return temp.slice(0,ind+5)
      //       }else if(temp.length-1-ind<4){
      //         let index = 4-(temp.length-1-ind);
      //         for(let i=0;i<index;i++){
      //           temp = temp + '0'
      //         }
      //         return temp
      //       }else{
      //         return temp
      //       }
      //     }
      //   }else{
      //     this.$message({message:'物料数量不能大于9999.9999',type:'error'});
      //         return ''
      //   }
      // }else{
      //   return ''
      // }
    }
  }
};
</script>

<style scoped></style>
